/* infocard */

.infoCard {
  padding: 20px;
  display: flex;
  position: fixed;
  overflow: hidden;
  flex-direction: column;
  border-radius: 20px;
  background-color: var(--almostBlack);
  color: white;
  align-items: center;
  border: 1px solid var(--bottomBorder);
  backdrop-filter: blur(8px);
  gap: 20px;
  text-align: center;
  width: 310px;
  padding-bottom: 0.5rem;
  height: fit-content;
  max-width: 100%;
  max-height: 745px;
  margin-left: 40px;
  z-index: 30;
  transition-duration: 0.7s;
  top: 50%;
  transform: translateY(-50%);
}

.infoCard .firstInformation,
.infoCard .moreInformation,
.infoCard .contactMe,
.infoCard h3 {
  transition-delay: 0.7s;
  transition-duration: 0.7s;
}

.infoCard h3 {
  text-transform: uppercase;
  color: var(--softWhite);
  margin: 0;
  font-weight: bold;
  margin: 1rem 0;
  padding-top: 0;
  margin-bottom: 0.5rem;
}

.profileContainer {
  position: relative;
  overflow: hidden;
  height: 260px;
  border-radius: 10px;
}

.profileContainer img {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  width: 100%;
  border-radius: 20px;
  transform: translate(-50%, -50%);
}

.firstInformation {
  width: 100%;
}

/* details */
.moreInformation {
  color: var(--softWhite);
  padding-bottom: 1.5rem;
  padding-top: 0;
}

.moreInformation p:first-child {
  font-size: 14px;
  color: var(--lightWhite);
}

.moreInformation h3 {
  margin-bottom: 1rem;
  color: var(--lightWhite);
}

.infoCard p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.ctaContainer {
  display: flex;
  flex-direction: column;
}

.contactMe .contactButton {
  margin-top: 1rem;
}

.socialLinks {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.socialLinks img {
  max-width: 45px;
  border: var(--border);
  border-radius: 50%;
  padding: 10px;
  margin: 0 10px;
}

.socialLinks img:hover,
.categoryIndicator:hover {
  background-color: var(--lightWhite);
}

.contactMe {
  padding-top: 10px;
  width: 100%;
}

/* collapsed card */
.collapsedCard .socialLinks {
  visibility: hidden;
  opacity: 0;
  display: none;
  transition: ease-in-out 500ms;
}

.collapsedCard {
  opacity: 0;
  padding: 20px 25px;
  position: fixed;
  overflow: hidden;
  transition-duration: 1s;
  transform: translateY(-50%);
}

.collapsedCard .moreInformation {
  margin-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.collapsedCard .firstInformation,
.collapsedCard .moreInformation,
.collapsedCard .contactMe,
.collapsedCard h3 {
  opacity: 0;
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
}

@media only screen and (min-width: 2500px) {
  .infoCard {
    gap: 40px;
    min-height: 350px;
    left: 8%;
  }
}

@media only screen and (max-width: 1300px) {
  .infoCard {
    display: none;
  }
}
/* HORIZONTAL CARD */
.cardHorizontal {
  flex-direction: row;
  min-height: unset;
  left: 0;
  padding-bottom: unset;
  padding: 10px 13px;
  border-radius: 40px;
  position: relative;
  margin: 2rem 0;
  transform: none;
  gap: 15px;
  width: fit-content;
}

.cardHorizontal .profileContainer {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.cardHorizontal .moreInformation {
  padding-bottom: 0;
}

.cardHorizontal .moreInformation p {
  text-align: left;
  font-size: 14px;
}
.cardHorizontal .socialLinks {
  margin-left: 10px;
}

.cardHorizontal .socialLinks img {
  margin: 0;
  max-width: 40px;
}
