/* stored colors */
#root {
  min-height: 100vh;
}

body {
  --bottomBorder: rgba(255, 255, 255, 0.1);
  --border: 1px solid #454545;
  --containerBackgroundColor: rgba(255, 255, 255, 0.1);
  --almostBlack: rgba(0, 0, 0, 0.15);
  --softBlack: rgba(0, 0, 0, 0.3);
  --lightBlack: rgba(0, 0, 0, 0.5);
  --darkbackground: rgba(0, 0, 0, 0.7);
  --fullBlack: rgba(0, 0, 0);

  /* font colors */
  --lightWhite: rgba(255, 255, 255, 0.5);
  --almostTransparent: rgba(255, 255, 255, 0.1);
  --softWhite: rgba(255, 255, 255, 0.7);
  --errorColor: #ab3428;

  /* box shadows */
  --simpleShadow: 0 0 10px 5px rgba(0, 0, 0, 0.1);

  /* active */
  --accentColor: #4da1a9;
  --secondaryAccentColor: #b4e7ce;
  --spanAccentColor: #ffd25a;
}

/* body */
body {
  background-color: #1d1d1f !important;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: white;
  padding: 0;
  height: 100%;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

p,
ul li {
  font-size: 17px;
  color: rgba(255, 255, 255, 0.85);
}

h3 {
  color: var(--secondaryAccentColor);
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 2rem;
  font-weight: 800;
}

.qualificationTitles,
.qualificationSection h3 {
  color: var(--spanAccentColor);
  font-size: 14px;
  margin: 0;
  margin-bottom: 10px;
}

.qualificationSection:nth-of-type(2) {
  border-top: var(--border);
  margin-bottom: 0;
}

.qualificationSection h3 {
  color: var(--lightWhite);
}

/* spline loading */

.spline-fade,
.backgroundContainer {
  opacity: 0;
  animation: fadeIn 5s ease-in;
}

.spline-fade.loaded,
.backgroundContainer.loaded {
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/*
.background-element {
  background: linear-gradient(to top, #1f1f21, rgba(0, 0, 0, 0.9) 50%),
    url(../assets//background-test.jpg) no-repeat;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100vh;
  left: 0;
}*/

.extended-body {
  max-width: 1700px;
  width: 100%;
  height: calc(100vh - 110px);
  margin: auto;
  position: relative;
}

body a {
  text-decoration: none !important;
  color: var(--accentColor);
}

/* containers */
.backgroundContainer {
  top: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.backgroundContainer .overlayContainer,
.componentcard .overlayContainer {
  background: linear-gradient(to top, #1d1d1f, rgba(0, 0, 0, 0.6));
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 6;
}

.backgroundContainer video,
.backgroundContainer img {
  position: absolute;
  top: 0%;
  left: 50%;
  width: 100%;
  z-index: 3;
  z-index: 3;
  transform: translateX(-50%);
}

.maxContainer {
  width: 90%;
  height: inherit;
  z-index: 10;
  margin: auto;
  padding: 4rem;
  position: relative;
  padding-left: 320px;
  padding-right: 100px;
  transition-duration: 0.7s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.maxContainer h1,
.extendedContainer h1,
.titleInformation h1 {
  margin-bottom: 2rem;
  font-size: 4rem;
  font-weight: bold;
  color: white;
}

.showcase--subpages {
  max-width: 80%;
}

.extendedContainer {
  padding: 4rem 180px 4rem 120px;
  margin: auto;
  position: relative;
}

.mainHeaderContainer {
  position: relative;
  max-height: 600px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.photographyPage > .mainHeaderContainer img {
  position: absolute;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  z-index: 4;
  transition: transform 0.3s ease;
}

.overlayShadow {
  position: absolute;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.3), var(--almostBlack));
  height: 100%;
  width: 100%;
  z-index: 5;
}

.contentContainer {
  background-color: var(--containerBackgroundColor);
  padding: 1.5rem;
  max-width: 1200px;
  margin: auto;
  border-radius: 5px;
  box-shadow: var(--simpleShadow);
}

/* basic visible-hiddenclass */

.visibleComp {
  height: 50px;
  transition-duration: 0.3s;
  opacity: 1;
  visibility: visible;
  margin-top: 1rem;
}

.hiddenComp {
  opacity: 0 !important;
  margin: 0;
  min-height: 0 !important;
  max-height: 0 !important;
  padding-top: 0;
  opacity: 0;
  visibility: hidden;
  transition-duration: 0.3s;
}

.hiddenComp p {
  display: none;
}

/* menu component */

.menuComponent:hover {
  opacity: 0.8;
}

.menuIcon {
  color: black;
  transition: ease-in-out 300ms;
}

.menuItemContainer {
  background-color: var(--almostBlack);
  -webkit-backdrop-filter: blur(15px);
  backdrop-filter: blur(15px);
  transition: all ease-in-out 150ms;
  border-radius: 45px;
  opacity: 1;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  padding: 4px 8px;
  position: fixed;
  /* right: calc(50% - 850px + 45px); */
  right: 50px;
  border: 1px solid var(--bottomBorder);
  justify-content: center;
}

.menuOptions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menuOptions > a {
  transition: ease-in-out 100ms;
}

.menuOptions > div a.active svg,
.menuOptions > div a.active:hover svg {
  background-color: var(--accentColor) !important;
  transition: ease-in-out 100ms;
  border-radius: 50%;
  padding: 12px;
  color: black;
}

.menuOptions a:hover svg {
  background-color: black;
}

.menuOptionsa a {
  width: fit-content;
}

.menuOptions .menuLabels {
  opacity: 0;
  width: 0;
  visibility: hidden;
  transition-duration: 0.1s;
  position: absolute;
  z-index: 0;
  padding: 0;
  text-transform: uppercase;
  top: 16px;
  color: transparent;
  font-size: 12px;
  right: 65px;
  background: var(--softWhite);
  cursor: pointer;
  border-radius: 20px;
}

.menuLabelContainer:hover .menuLabels {
  opacity: 1;
  padding: 4px 14px;
  visibility: visible;
  display: block;
  width: fit-content;
  color: black;
  z-index: 1;
  right: 65px;
}

.menuLabels p {
  padding: 0;
  margin: 0;
}

.menuLabelContainer {
  display: flex;
  opacity: 0;
  visibility: hidden;
  flex-direction: row;
  position: relative;
  align-items: center;
}

.menuOptions .menuLabelContainer,
.menuOptions .menuLabelContainer {
  opacity: 1;
  visibility: visible;
}

.menuOptions svg {
  padding: 10px;
  border-radius: 50%;
  font-size: 46px;
  fill: var(--softWhite) !important;
  font-weight: 100;
}

.menuOptions #back {
  fill: var(--accentColor) !important;
}

.menuOptions a {
  padding: 5px 0;
  font-size: 16px;
  display: block;
  text-decoration: none;
}

/* expanded menu*/
.expandedMenu {
  position: fixed;
  top: 3rem;
  z-index: 30;
  margin-left: 130px;
  border: 1px solid var(--bottomBorder);
  backdrop-filter: blur(10px);
  background-color: rgba(39, 39, 39, 0.5);
  opacity: 1;
  transition: ease-in-out 700ms;
}

.extraMenuOptions {
  height: fit-content;
  padding-top: 10px;
  border-top: 1px solid var(--bottomBorder);
  opacity: 1;
  transition-duration: 0.7s;
  display: flex;
  width: fit-content;
  padding-bottom: 10px;
  opacity: 1;
  visibility: visible;
}

.hiddenExtraOptions {
  opacity: 0 !important;
  height: 0 !important;
  width: 0 !important;
  padding-top: 0;
  opacity: 0;
  visibility: hidden;
  position: relative;
  transition-duration: 0.2s;
}

.hiddenExtraOptions .menuOptions a {
  display: none;
}

/* hidden icon */
.hiddenMenuIcon {
  visibility: 0;
  opacity: 0;
  position: absolute;
  height: 0;
  z-index: 1;
  margin-left: 310px;
  transition-delay: 0s;
  transition: ease-in-out 100ms;
  transition-duration: 0.3s;
}

.hiddenMenuIcon span {
  display: none;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 8px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 9999;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* introtext */
.firstInformation .introText:first-of-type {
  margin-bottom: 1rem;
  font-size: 5rem;
  font-weight: bold;
  max-width: 650px;
}

.introText:last-of-type {
  font-size: 20px;
  font-weight: 100;
  color: var(--lightWhite);
}

/* buttons */
.buttonContainer {
  padding-top: 1rem;
  display: flex;
  flex-direction: row;
  gap: 5px;
  flex-wrap: wrap;
  align-items: center;
}

.buttonContainer svg {
  fill: var(--secondaryAccentColor) !important;
  margin-right: 10px;
}

.socialmediaCont .socialLinks img {
  border-radius: 0;
  max-width: 60px;
  cursor: pointer;
  margin-left: 0;
}

.buttonContainer a {
  text-decoration: none !important;
}

.contactButton {
  background-color: var(--accentColor);
  border: 1px solid var(--accentColor);
  outline: 0 !important;
  border-radius: 20px;
  padding: 7px 20px;
  width: fit-content;
  top: 0;
  position: relative;
  transition: ease-in-out 100ms;
  justify-content: center;
  display: flex;
  font-size: 14px;
  text-transform: uppercase;
  align-items: center;
  color: black;
  text-decoration: none !important;
  margin: 3px;
  margin-bottom: 1rem;
}

.contactButton svg {
  margin-right: 10px;
  fill: #000 !important;
}

.flexContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.simpleButton {
  border: 1px solid var(--accentColor);
  background: transparent;
  outline: 0 !important;
  text-decoration: none !important;
  border-radius: 20px;
  padding: 7px 20px;
  width: fit-content;
  top: 0;
  position: relative;
  transition: ease-in-out 100ms;
  justify-content: center;
  display: flex;
  font-size: 14px;
  text-transform: uppercase;
  align-items: center;
  color: white;
  margin: 3px;
}

.chevronButton {
  display: inline-flex;
  padding: 0;
  align-items: center;
  outline: 0;
  border: 0;
  background: transparent;
  margin: 1rem 0 0;
  gap: 3px;
  cursor: pointer;
}

.chevronButton.active svg {
  fill: var(--accentColor) !important;
}

.chevronButton p {
  display: inline;
  margin-bottom: 0;
  color: var(--softWhite);
}

.chevronButton svg {
  position: relative;
  line-height: unset;
  fill: var(--spanAccentColor) !important;
  transition: ease-in-out 100ms;
  left: 0;
  font-size: 17px;
  cursor: pointer;
}

.chevronButton:hover p {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.chevronButton:hover svg {
  left: 4px;
  position: relative;
}

.simpleButton:hover,
.contactButton:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  position: relative;
  top: -3px;
  color: black;
  border: 1px solid white;
  background-color: white;
}

.textButton {
  color: var(--accentColor);
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  text-decoration: underline;
  text-underline-offset: 5.5px;
  margin-top: 0;
}

.textButton:hover {
  background: rgba(247, 197, 72, 0.3);
}

/*swiper*/
.swiper {
  width: 100% !important;
  overflow: visible;
  max-width: 1100px;
  margin: 3rem auto 2rem;
  padding-bottom: 3rem;
  border: 0px !important;
  overflow: hidden !important;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex !important;
  justify-content: center;
  overflow: visible;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: auto;
  max-width: 1200px;
  max-height: 600px;
  margin: auto;
  height: auto;
}

.swiper-button-prev,
.swiper-button-next {
  border-radius: 50%;
  background-color: #a0a0a0;
  height: 40px !important;
  width: 40px !important;
  padding: 10px;
  display: flex;
  align-items: center !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  filter: opacity(0.7);
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: unset !important;
  color: white !important;
  font-weight: 700 !important;
}

.swiper-pagination-bullet {
  height: 14px !important;
  width: 14px !important;
  border: 1px solid var(--bottomBorder) !important;
  margin: 10px 6px !important;
  background-color: var(--lightWhite) !important;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  z-index: 11;
}

.swiper-pagination-bullet-active {
  background-color: var(--secondaryAccentColor) !important;
}

/* mui */
.MuiSvgIcon-root {
  fill: white !important;
}

/* contact */
.contact .buttonContainer p {
  color: var(--softWhite);
}

.contactFields {
  flex-direction: column;
}

.contactBox {
  margin: 0;
  transition: ease-in-out 100ms;
  transition-duration: 0.5s;
  min-height: 400px;
}

.succeedForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: ease-in-out 100ms;
  transition-duration: 0.5s;
}

.contactBox label {
  display: block;
  margin-bottom: 3px;
  font-size: 13px;
  color: var(--lightWhite);
}

.contactBox input,
.contactBox textarea {
  display: block;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  height: 45px;
  background-color: var(--softWhite);
  outline: 0;
  border: var(--border);
  color: black;
}

.contactBox .mainMessage textarea,
.passwordLocked textarea {
  height: 130px;
  margin: 30px 0;
  padding: 10px;
}

.passwordLocked #openLock,
.mobilePassword #openLock {
  fill: var(--lightWhite) !important;
}

.contactBox input:focus,
.contactBox .mainMessage textarea:focus {
  border: 1px solid #ff4b00 !important;
}

.contactBox input::placeholder {
  font-size: 13px;
}

.contactFields {
  margin: 1rem 0;
}

.succeedForm span {
  color: var(--secondaryAccentColor);
  font-size: 3rem !important;
  margin-bottom: 1.5rem;
}

.succeedForm p {
  color: var(--softWhite);
  font-size: 15px;
}

/*PROJECTS */
/* projects */
.outerProjectCard,
.outerComponentCard {
  margin: unset;
  width: 100%;
  max-width: 100%;
  min-width: 300px;
}

.outerProjectCard a {
  text-decoration: underline;
  color: white;
  text-transform: uppercase;
  text-underline-offset: 5.5px;
  width: unset !important;
}

.outerProjectCard h3 {
  width: fit-content !important;
}

.projectCard {
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  border: var(--border);
  box-shadow: var(--simpleShadow);
  justify-content: space-between;
  background-color: var(--almostBlack);
  overflow: hidden;
  margin-bottom: 0.5rem;
  transition-duration: 300ms;
  transition: ease-in-out 300ms;
  top: 0;
}

/* component cards */
.outerComponentCard {
  position: relative;
  min-width: unset;
  margin-bottom: 1.5rem;
  height: calc(100% - 20px);
}

.outerComponentCard .projectCard {
  backdrop-filter: none;
}

.componentcard {
  position: relative;
  border: var(--border);
  margin-bottom: 0.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 280px;
  top: 0;
  transition: ease-in-out 300ms;
  height: 100%;
}

.componentcard h3 {
  margin-bottom: 5px;
  width: fit-content;
}

.componentcard img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.componentcard .projectDetail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.lockedInd {
  position: absolute;
  top: 20px;
  opacity: 0.6;
  transition: ease-in-out 200ms;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lockedInd svg {
  fill: #000 !important;
  background-color: var(--spanAccentColor);
  border-radius: 50%;
  padding: 5px;
  z-index: 10;
  height: 30px;
  width: 30px;
}

.lockedInd:hover::after {
  opacity: 1;
  width: 147px;
  color: var(--lightBlack);
  visibility: visible;
}

.lockedInd::after {
  content: "Content is locked";
  position: absolute;
  width: max-content;
  opacity: 0;
  break-inside: avoid;
  visibility: 0;
  white-space: nowrap;
  overflow: hidden;
  color: transparent;
  width: 0;
  font-size: 14px;
  right: 0;
  z-index: 9;
  background-color: var(--spanAccentColor);
  padding: 5px 10px;
  border-radius: 30px;
  transition: ease-in-out 200ms;
}

.projectCard:hover,
.componentcard:hover {
  position: relative;
  top: -5px;
  border: 1px solid var(--accentColor);
}

.projectCard:hover .backgroundPhoto,
.projectCard:hover .photoGalleryHighlight {
  opacity: 1;
}

.backgroundPhoto,
.photoGalleryHighlight {
  opacity: 0.7;
  transition: ease-in-out 300ms;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  overflow: hidden;
}

.photoGalleryHighlight {
  height: 350px;
}

.backgroundPhoto img {
  width: 100%;
  transition: ease-in-out 600ms;
}

.projectCard:hover .backgroundPhoto img,
.projectCard:hover .photoGalleryHighlight img {
  width: 105%;
  transition: ease-in-out 200ms;
}

.projectDetail {
  color: white;
  width: 100%;
  flex-direction: column;
  display: flex;
  padding: 20px;
  justify-content: space-between;
}

.projectDetail h4,
.titleInformation h4,
.extendedContainer h4 {
  font-size: 14px;
  color: var(--lightWhite);
  font-weight: bolder;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.photoGallery h3 {
  margin-bottom: 10px;
}

.projectDetail .dateInd {
  font-size: 12px;
  color: var(--lightWhite);
}

.outerProjectCard:hover .lockedInd {
  opacity: 1;
}

.outerProjectCard:hover .backgroundPhoto {
  opacity: 1;
}

.outerProjectCard:hover .projectCard {
  box-shadow: var(--simpleShadow);
}

.technologyCategories {
  padding: 4px 10px;
  background: var(--softWhite);
  border-radius: 20px;
  text-transform: uppercase;
  color: black;
  font-size: 11px;
  width: fit-content;
  margin: 3px 0 0 0;
}

.topCategory {
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 20px;
}

/* modal */
.modal-lg,
.modal-xl {
  --bs-modal-width: 1200px;
}

.componenDemoModal .modal-content {
  background-color: var(--darkbackground);
  border: var(--border);
  padding: 0;
}

.componenDemoModal.modal.show {
  backdrop-filter: blur(5px);
}

.componenDemoModal .modal-body {
  padding: 10px;
}

.modalContainer {
  padding: 30px;
}

.modalContainer h3 {
  margin-bottom: 1.5rem;
}

.modal-body {
  position: relative;
}

.modal.fade .modal-dialog {
  transition: ease-in-out 200ms;
  opacity: 1;
}

.modal-content .modal-body .closeDownBtn {
  background-color: var(--lightWhite);
  border-radius: 50%;
  color: #1d1d1f;
  padding: 5px;
  height: 1.3em;
  width: 1.3em;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

.modal-content .modal-body .closeDownBtn:hover {
  background-color: var(--softWhite);
}

.modal-content .modal-body div div .backgroundPhoto {
  opacity: 1;
  width: 100%;
  height: auto;
}

.modal-content .modal-body div div .backgroundPhoto img {
  width: 100%;
}

.modal-backdrop {
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.7;
  backdrop-filter: blur(10px);
}

.modalContainer .keyInfoContainer {
  flex-wrap: nowrap;
}

.modalContainer .keyInfoContainer .highlightInformation {
  margin: 1rem 0;
  padding: 1.7rem 5px 0;
}

.modalContainer .keyInfoContainer .highlightInformation h4 {
  font-size: 24px;
}

/* filter */

.filterContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 20px;
  background: var(--almostBlack);
  backdrop-filter: blur(5px);
  border: var(--border);
  border-radius: 10px;
  width: fit-content;
  color: var(--softWhite);
  margin-bottom: 1.3rem;
  justify-content: flex-start;
}

.allProjectselector {
  width: 100%;
  padding: 0;
}

.filterContainer p {
  color: var(--lightWhite);
  margin-right: 15px;
  margin-bottom: 0;
}

.filterContainer
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: var(--lightWhite) !important;
}

.filterContainer
  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: var(--spanAccentColor);
  border-width: 0.5px;
}

.customFormControl [role="button"] {
  padding: 9.5px !important;
  color: var(--lightWhite);
}

.customFormControl input {
  padding: 8px 14px !important;
}

.customFormControl {
  color: white !important;
}

.customFormControl:focus {
  border-color: var(--accentColor) !important;
}

.Mui-selected:hover {
  background-color: transparent !important;
}

.Mui-selected {
  background-color: transparent !important;
  color: var(--spanAccentColor) !important;
}

.filteredItemCollection {
  justify-content: flex-start;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  overflow: hidden;
  gap: 20px;
  width: 100%;
  padding-top: 1rem;
}

/* power platform */
.platformCard {
  background: radial-gradient(
      circle at top,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)
    ),
    url(/src/assets/background-powerplatform.jpeg);
  background-size: cover;
  padding: 20px;
  filter: saturate(0.7);
  border: 0px !important;
}

.platformCard:hover {
  border: 1px solid var(--accentColor) !important;
}

.powerPlatform-outerCont {
  position: relative;
  max-height: fit-content;
  border: 1px solid var(--border);
  padding: 0 20px;
}

.powerPlatform-outerCont .projectCard {
  background-color: #19191a !important;
  z-index: 10;
  position: relative;
}

.powerPlatform-outerCont .projectCard .projectDetail p,
.powerPlatform-outerCont .projectCard .projectDetail ul li {
  font-size: 15px !important;
}

.powerPlatform-outerCont .projectCard:hover {
  top: 0;
  border: var(--border);
}

.projectSelector {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.css-9s8aw7 {
  display: none !important;
}

.recruiterInformation {
  padding: 25px 2rem 25px;
  margin: 3rem 0;
  border: 1px solid rgba(255, 210, 90, 0.5);
  border-left: 7px solid var(--spanAccentColor);
  background: rgba(255, 210, 90, 0.05);
}

.recruiterInformation .closeDownMenu {
  float: right;
  opacity: 1;
  cursor: pointer;
}

.recruiterInformation .closeDownMenu:hover {
  opacity: 0.6;
}

/** form control **/
.form-check-input:checked {
  background-color: var(--accentColor);
  border-color: var(--accentColor);
}

/* animation */
.slide-enter {
  transform: translateY(-50px);
  opacity: 1;
  transition: ease-in-out 300ms;
}

.slide-enter-active {
  transform: translateY(0);
  transition: all 300ms;
  opacity: 1;
}

.slide-exit {
  transform: translateY(50px);
  opacity: 0.3;
  transition: all 300ms;
}

.slide-exit-active {
  transform: translateX(200px);
  transition: all 300ms;
  opacity: 0;
}

/* content */
.descriptionContainer {
  color: var(--softWhite);
  max-width: 75%;
  font-size: 20px;
  margin: 3rem 0;
}

.keyInfoContainer {
  display: flex;
  margin-top: 1.5rem;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.keyInfoContainer p {
  margin: 0;
  padding: 0;
}

.highlightInformation {
  border-top: 1px solid var(--bottomBorder);
  display: flex;
  margin: 2rem 0;
  flex-direction: column;
  gap: 10px;
  color: var(--lightWhite);
  min-width: 45%;
  max-width: 300px;
  padding: 2.5rem 5px 0;
}

.highlightInformation h4 {
  color: var(--softWhite);
  font-weight: 800;
  font-size: 33px;
}

.highlightInformation svg {
  fill: var(--spanAccentColor) !important;
}

.highlightInformation p {
  margin: 0;
  padding: 0;
  color: var(--softWhite);
}

.imageContainer img {
  width: 100%;
}

.mainPhoto {
  width: 100%;
}

/* mackbook mockup positioning */
.interactiveBlock {
  background-color: var(--almostBlack);
  padding: 30px 20px;
  top: 0;
  border-radius: 15px;
  border: var(--border);
  box-shadow: var(--simpleShadow);
  margin-top: 4rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  justify-content: flex-start;
}

.interactiveBlock:hover {
  transition: ease-in-out 200ms;
  position: relative;
  top: -5px;
}

.interactiveBlock .descriptionContainer p {
  color: var(--fullBlack);
}

.interactiveBlock .descriptionContainer h3 {
  margin-bottom: 1rem;
}

.interactiveBlock .descriptionContainer {
  margin-top: 0;
  margin-bottom: 1rem;
}

.interactiveBlock .descriptionContainer .swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, 50px);
}

.interactiveBlock .descriptionContainer .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, 50px);
}

.instyleMockup {
  position: relative;
  height: 610px;
  width: 100%;
}

.instyleMockup .macBook-bgMockup {
  position: absolute;
  height: 100%;
  top: 0;
  width: 90%;
  left: 50%;
  transform: translateX(-50%);
}

.instyleMockup .macMockup::after {
  content: "";
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 77px;
  height: 12px;
  background-color: black;
  position: absolute;
  z-index: 5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.macMockup {
  width: 71.8% !important;
  position: absolute !important;
  background-color: black;
  left: 50%;
  transform: translateX(-50%);
  top: 2% !important;
  height: calc(100% - 14%);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.object--fullLengthScroll {
  width: 100%;
  height: auto;
  max-height: unset !important;
  overflow-x: hidden;
  overflow-y: scroll;
}

.object--fullLengthScroll::-webkit-scrollbar,
.macMockup::-webkit-scrollbar {
  width: 0;
  display: none !important;
}

/* component cards scrollable mockups */
.componentScrollShowcase {
  position: relative;
  height: 330px;
}

.tabletView {
  height: 450px;
}

.componentScrollShowcase .macBook-bgMockup {
  position: absolute;
  height: auto;
  width: 100%;
}

.componentScrollShowcase .macMockup {
  width: 80% !important;
  z-index: 5;
  height: calc(100% - 8%) !important;
  overflow: scroll;
}

.componentScrollShowcase .ipadMockup {
  width: 88.8% !important;
  z-index: 5;
  height: calc(100% - 15.5%) !important;
  overflow: scroll;
  top: 7.5% !important;
  left: 50.5%;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

/* technologies used */
.technologyContainer {
  width: -moz-fit-content;
  width: fit-content;
  padding-top: 1rem;
  color: var(--lightWhite);
}

.projectTechnologies {
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.projectTechnologies img {
  max-height: 70px;
  position: relative;
  top: 0px;
  transition-duration: 0.3s;
  padding: 15px;
}

.projectTechnologies img:hover,
.serviceIcons:hover {
  position: relative;
  transform: scale(1.2);
  filter: drop-shadow(0 0 6px var(--accentColor));
}

.projectTechnologies .technologyCategories {
  display: inline-flex;
  margin-right: 0px;
  margin-bottom: 4px;
  margin-right: 5px;
}

.technologyContainer .technologyCategories {
  display: inline-flex;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

/* skills */
.skillContainer,
.designContainer {
  background-color: var(--almostBlack);
  color: var(--softWhite);
  display: flex;
  width: 100%;
  backdrop-filter: blur(3px);
  border: 1px solid var(--almostTransparent);
  flex-direction: column;
  gap: 20px;
  word-break: break-word;
  flex-wrap: wrap;
  border-radius: 10px;
  min-height: 280px;
  justify-content: flex-start;
  transition: ease-in-out 300ms;
  position: relative;
  top: 0;
  margin-bottom: 1.5rem;
}

.designContainer svg {
  fill: var(--spanAccentColor) !important;
  font-size: 30px;
}

.skillContainer {
  padding: 30px;
  min-height: 435px;
}

.skillContainer:hover,
.designContainer:hover {
  border: 1px solid var(--accentColor);
  top: -5px;
  position: relative;
}

.designContainer img {
  max-width: 50px;
  font-size: 2rem;
  color: var(--spanAccentColor);
}

.skillContainer .skillContainer .chartContainer {
  width: 90%;
  display: block;
  margin: 0 auto;
}

.softwareKnowledge {
  display: flex;
  flex-direction: row;
  gap: 7px;
  border-radius: 7px;
  margin: 0.5rem 0;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.sotfwareKnowledge p {
  color: var(--fullBlack);
}

.progressbar-container {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 70%;
}

/* progres bar*/
.progressbar {
  width: 100%;
  height: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  border-radius: 5px;
  background-color: #494b52;
  box-shadow: 0px 0px 10px rgba(17, 17, 17, 0.3);
  overflow: hidden;
}

.bar {
  width: 0%;
  background: var(--accentColor);
  background: linear-gradient(
    to right,
    var(--spanAccentColor) 0%,
    var(--secondaryAccentColor) 100%
  );
}

/* microsoft related apps */
.Skills .MuiPaper-root {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  margin-top: 0;
}

.serviceIcons {
  max-width: 80px;
  margin: 1rem 0;
  transition-duration: 0.3s;
}

.softwareItem {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid var(--almostBlack);
}

.secondaryMenu {
  flex-wrap: wrap;
}

.secondaryMenu p {
  margin: 0;
  padding: 0;
  color: var(--lightWhite);
  font-size: 14px;
  margin-right: 20px;
}

.designContainer {
  background-color: var(--almostBlack);
  padding: 30px 25px;
}

/* absolute center */
.loadingScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
  min-height: 300px;
}

/* loading */
.loadingCounter {
  color: var(--lightWhite);
  margin-top: 0.5rem;
  font-size: 13px;
}

/* skills */
.myWork {
  margin-top: 2rem;
  max-width: 1200px;
}

/** category indicator **/
.categoryIndicator {
  background-color: var(--almostBlack);
  color: var(--lightWhite);
  padding: 6px 20px;
  border-radius: 20px;
  border: 1px solid var(--bottomBorder);
  width: fit-content;
  margin-bottom: 10px;
}

/* password */
.passwordLocked {
  transition: ease-in-out 200ms;
  background: var(--almostBlack);
  border: 1px solid var(--bottomBorder);
  padding: 50px 40px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 670px;
  width: calc(100% - 30px);
  margin: 1rem auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.passwordInput,
.errorState {
  border-radius: 30px;
  width: 100%;
  max-width: 300px;
  display: block;
  background-color: var(--softWhite);
  outline: 0;
  color: var(--lightBlack);
  border: 0;
  padding: 0 10px;
}

.mobilePassword {
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* shake animation */
@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

.passwordInput:focus,
.errorState:focus {
  border: none !important;
  outline: 0 !important;
}

.errorState {
  box-shadow: 0 0 0.2em red;
  animation: shake 0.2s ease-in-out 0s 2;
}

.formSubmit {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
}

.formSubmit button:hover {
  position: relative;
  top: 0;
}

.errorLog,
.hiddenAnswer {
  color: var(--softWhite);
}

.errorFeedback {
  color: var(--errorColor);
}

.hintAnswer {
  padding-top: 1rem;
  margin-top: 2.5rem;
  border-top: 1px solid var(--bottomBorder);
  color: var(--lightWhite);
  cursor: pointer;
}

.hintAnswer:hover p {
  text-decoration: underline;
}

.hintAnswer svg {
  fill: var(--accentColor) !important;
}

/* timeline */
.vertical-timeline {
  width: 100% !important;
}

.vertical-timeline {
  max-width: unset !important;
}

.vertical-timeline::before {
  background: var(--lightWhite) !important;
  width: 2px !important;
}

.vertical-timeline-element-title {
  color: var(--secondaryAccentColor);
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 800;
}

.vertical-timeline-element-subtitle {
  font-size: 15px;
  color: var(--softWhite);
  margin-top: 1rem !important;
}

.vertical-timeline-element-description {
  font-size: 14px;
  margin-top: 1rem;
}

.vertical-timeline-element-content {
  box-shadow: 0 0 10px 0 var(--almostBlack) !important;
  border: var(--border);
  border-radius: 10px;
  background: var(--almostBlack) !important;
}

.vertical-timeline-element-icon {
  -webkit-box-shadow: 0 !important;
  box-shadow: 0 !important;
  border: 1.5px solid var(--bottomBorder) !important;
}

/*cv part*/
/* interactive cv */
.qualificationSection ul li {
  padding: 10px 0;
}

.qualiDate {
  display: block;
  font-size: 12px;
}

.experienceChart {
  margin: 15px 0;
}

/* mui fixes */
.MuiPaper-root {
  background-color: var(--almostBlack) !important;
  backdrop-filter: blur(10px);
  color: var(--softWhite) !important;
  margin: 10px 0px;
  border-radius: 10px !important;
  padding: 0.6rem;
}

.customTable {
  transition: ease-in-out 300ms;
  box-shadow: none !important;
  margin-top: 0;
  padding: 0;
}

.MuiTableCell-root {
  color: var(--softWhite) !important;
  border-bottom: 1px solid var(--bottomBorder) !important;
}

.MuiTableRow-root.active,
.MuiTableRow-root:hover {
  background-color: var(--almostTransparent) !important;
}

.MuiTableRow-root.active .MuiTableCell-root {
  color: var(--accentColor) !important;
  font-weight: 500 !important;
}

.MuiTableRow-root:hover {
  cursor: pointer !important;
}

.MuiTableRow-root:last-child td,
.MuiTableBody-root .MuiTableRow-root:last-child th {
  border-bottom: 0 !important;
}

.MuiTableRow-head th {
  font-weight: 600 !important;
  border-bottom: 1px solid var(--lightWhite) !important;
}

.MuiTableHead-root .MuiTableRow-root:hover {
  background-color: unset !important;
}

.MuiAccordionSummary-content {
  color: var(--secondaryAccentColor);
}

/* timeline */

.experienceChart {
  position: relative;
}

.experienceChart ul.rb {
  margin: 0.5em 0;
  padding: 0;
  display: inline-block;
  width: 100%;
}

.experienceChart ul.rb li {
  list-style: none;
  margin: auto;
  min-height: 50px;
  border-left: 1px dotted var(--lightWhite);
  padding: 0 0 40px 40px;
  position: relative;
}

.experienceChart ul.rb li::before {
  position: absolute;
  left: -11px;
  top: 0px;
  content: " ";
  border-radius: 500%;
  background: var(--softWhite);
  height: 20px;
  width: 20px;
  transition: all 500ms ease-in-out;
}

.experienceChart ul.rb li:first-child:before {
  background: var(--accentColor) !important;
}

.experienceChart ul.rb li:first-child .timestamp {
  color: var(--secondaryAccentColor) !important;
}

.experienceChart ul.rb li:hover::before {
  border-color: #232931;
  transition: all 1000ms ease-in-out;
}

ul.rb li .timestamp {
  color: var(--lightWhite);
  position: relative;
  font-size: 14px;
  margin-bottom: 1rem;
}

.experienceChart .employmentDetails ul li::before {
  border-left: 0 !important;
  width: 0;
  height: 0;
  background: none;
}

.aboutme-section {
  max-width: 90%;
  margin-bottom: 3rem;
}

.employmentTitle {
  min-width: 40%;
}

.employmentDetails {
  width: 100%;
}

.experienceChart .employmentDetails ul {
  margin-top: 2.5rem;
}

.experienceChart .employmentDetails ul li {
  border-left: 0 !important;
  list-style: circle;
  padding: 5px 7px !important;
  font-size: 15px;
  min-height: 25px !important;
  color: var(--softWhite);
}

.rb-item {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.item-title {
  color: #fff;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 5px;
}

.item-subtitle {
  font-size: 14px;
  color: var(--softWhite);
}

/* chart */
.VictoryContainer svg g path {
  transition: ease-in-out 150ms;
  transition-duration: 0.15s;
}

.VictoryContainer svg g path:hover {
  fill: red !important;
  box-shadow: var(--simpleShadow) !important;
}

@media only screen and (min-width: 2500px) {
  .maxContainer,
  .extendedContainer {
    height: 100vh;
    max-width: 1920px;
  }

  .extendedContainer {
    height: 100%;
  }

  .maxContainer h1,
  .extendedContainer h1 .extended-body {
    max-width: unset;
  }

  .filteredItemCollection {
    grid-template-columns: repeat(1, 1fr);
    /* grid-template-columns: repeat(2, 1fr); */
  }

  .backgroundPhoto img,
  .photoGalleryHighlight img {
    height: auto;
    width: 100%;
    transition: ease-in-out 150ms;
    /*     height: 100%;
    width: auto; */
  }

  .projectCard:hover .backgroundPhoto img,
  .projectCard:hover .photoGalleryHighlight img {
    width: 104%;
    height: auto;
  }

  .technologyCategories {
    font-size: 12px;
  }

  .menuItemContainer {
    right: calc(50% - 1050px + 45px);
    padding: 5px 7px;
  }

  .menuOptions {
    gap: 5px;
  }

  .menuOptions span {
    font-size: 30px;
    color: var(--softWhite);
    font-weight: 100;
  }

  .backgroundContainer {
    height: 60%;
    overflow: hidden;
  }

  .swiper-slide img {
    max-height: 800px;
  }

  .swiper {
    max-width: unset;
  }
}

@media only screen and (max-width: 2500px) {
  .extended-body {
    max-width: unset;
  }

  .maxContainer,
  .extendedContainer {
    height: 100vh;
    max-width: 1920px;
  }

  .extendedContainer {
    height: 100%;
  }
}

@media only screen and (max-width: 1300px) {
  .maxContainer,
  .extendedContainer {
    padding-left: 20px;
  }

  .extended-body {
    height: inherit;
  }
}

@media only screen and (max-width: 2000px) {
  .menuItemContainer {
    right: 50px;
  }

  p {
    font-size: 15px;
  }

  .swiper-slide img {
    max-width: 900px;
  }

  .maxContainer {
    max-width: 2100px !important;
    margin: auto;
  }
}
