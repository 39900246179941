/*** TABLET ***/
@media only screen and (max-width: 1650px) {
  .backgroundPhoto img,
  .projectCard:hover .backgroundPhoto img {
    height: 100%;
    width: auto;
  }
  body {
    height: 100%;
  }
  p,
  ul li {
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 100;
  }
}
@media only screen and (max-width: 1100px) {
  .backgroundPhoto img {
    height: 100%;
    width: auto;
  }

  .projectSelector {
    flex-wrap: nowrap;
  }

  .technologyCategories {
    font-size: 10px;
  }
  p,
  ul li {
    font-size: 16px;
  }

  .projectDetail {
    padding: 30px 20px;
  }
}

/*** MOBILE ***/
@media only screen and (max-width: 932px) {
  .extendedContainer,
  .maxContainer {
    height: unset;
    padding: 25px 15px;
    width: 100%;
    margin: unset;
  }

  .aboutme-page {
    max-width: unset;
  }

  .openMenuOverlay {
    position: fixed;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    z-index: 20;
    transition-duration: 0.5s;
  }

  .extended-body {
    overflow-x: hidden;
  }

  .maxContainer h1,
  .extendedContainer h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    max-width: 90%;
  }

  p,
  ul li {
    font-size: 15px;
    line-height: 1.4;
  }

  h3 {
    margin-bottom: 1rem;
  }

  .hamburger-menu {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 10px 11px;
    top: 45px;
    min-width: 45px;
    right: 0;
    transform: translate(-50%, -50%);
    z-index: 300;
  }

  .skillContainer {
    padding: 20px;
    margin-bottom: 0;
    min-height: unset;
  }

  .descriptionContainer {
    margin: 1.5rem 0 1rem;
    max-width: 100%;
  }

  .projectDetail p:first-of-type {
    font-size: 14px;
  }

  .modalContainer .keyInfoContainer .highlightInformation h4,
  .highlightInformation h4 {
    font-size: 20px;
  }

  .projectTechnologies {
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 0.5rem;
  }

  .highlightInformation {
    gap: 5px;
  }

  .softwareKnowledge {
    gap: 5px;
    margin-top: 0;
  }

  .technologyCategories {
    padding: 3px 7px;
    margin-right: 0 !important;
    margin-bottom: 0;
  }

  .projectSelector {
    flex-wrap: wrap;
    margin-top: 1rem;
  }

  .mobileCard {
    flex-direction: column;
    align-items: center;
    text-align: left;
    margin: 0;
    margin-top: 2rem;
    padding: 15px;
    align-items: flex-start;
    gap: 0;
    color: white;
    align-items: center;
    background-color: #151516;
    border: var(--border);
    border-radius: 10px !important;
    opacity: 0;
    visibility: hidden;
    width: 0;
    transition: ease-in-out 100ms;
  }

  .mobileCard h3 {
    font-size: 14px;
    opacity: 0;
    width: 0;
    visibility: hidden;
    color: var(--spanAccentColor);
    margin-bottom: 1rem;
    transition: ease-in-out 100ms;
  }

  .softwareDescription p {
    font-size: 12.5px !important;
  }

  .instyleMockup {
    height: 400px;
  }

  .swiper {
    margin-bottom: 0 !important;
  }

  .swiper-slide img {
    max-width: 100%;
    max-height: unset;
  }

  .passwordInput,
  .errorState {
    max-width: unset;
    padding: 8px 15px;
    font-size: 14px;
    min-width: 300px;
    margin-top: 1rem;
  }

  .powerPlatform-outerCont {
    padding: 0;
  }

  .formSubmit {
    flex-direction: column;
    align-items: center;
  }

  .mobileCard p:nth-child(3) {
    font-size: 13px;
    margin: 0;
    padding-top: 13px;
  }

  .contactBox {
    margin-top: 2rem;
  }

  .menuOptions .menuLabelContainer,
  .menuOptions .menuLabelContainer a {
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
  }

  .menuLabelContainer:hover .menuLabels,
  .mobileCard a:hover .buttonContainer p,
  .mobileCard a:hover .buttonContainer svg {
    color: var(--lightWhite);
    fill: var(--lightWhite) !important;
  }

  .menuLabelContainer a:hover {
    color: var(--accentColor);
  }

  .firstInformation {
    height: unset;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: flex-end;
    width: 97%;
    margin-bottom: 1rem;
    justify-content: space-between;
  }

  .myWork {
    margin-top: 1rem;
  }

  /* menu */

  .mobileMenuItemContainer {
    width: 0;
    opacity: 0;
    padding: 20px 14px;
    border-radius: 10px;
    bottom: 10px;
    right: 10px;
    top: 10px;
    visibility: hidden;
    /* background-color: rgba(29, 29, 31, 0.55); */
    background-color: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(20px);
    position: absolute;
    transition-duration: 0.1s;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .mobileMenuIcon {
    transition-duration: 0.4s;
    vertical-align: middle;
    cursor: pointer;
  }

  .mobileMenuIcon:hover,
  .mobileMenuIcon.active:hover {
    opacity: 0.8;
  }

  .menuOptions > div a.active .menuLabels {
    color: var(--accentColor) !important;
  }

  .openMenu {
    transition-duration: 0.4s;
    border-radius: 5px;
    bottom: 5px;
    right: 5px;
    top: 5px;
    opacity: 1;
    width: 300px;
    z-index: 40;
    visibility: visible;
    position: fixed;
  }

  .menuOptions .menuLabels {
    opacity: 1;
    width: auto;
    visibility: visible;
    position: unset;
    z-index: 40;
    padding: 4px 14px;
    font-size: 14px;
    background: none;
    color: var(--softWhite);
    margin-bottom: 0;
  }

  .menuOptions {
    align-items: flex-start;
  }

  .expandedMenu {
    top: 60px;
  }

  .menuItemContainer,
  .menuOptions {
    flex-direction: column;
    opacity: 0;
    visibility: hidden;
    width: 0;
    padding-top: 0;
    gap: 20px;
    justify-content: space-around;
    transition: ease-in-out 100ms;
  }

  .openMenu .menuOptions {
    width: 100%;
    gap: 8px;
    opacity: 1;
    visibility: visible;
    transition: ease-in-out 100ms;
  }

  .openMenu .mobileCard {
    width: 100%;
    opacity: 1;
    visibility: visible;
    transition: ease-in-out 100ms;
    min-width: 271px;
    min-height: 155px;
  }

  .openMenu .mobileCard h3 {
    opacity: 1;
    width: auto;
    visibility: visible !important;
  }

  .menuOptions svg,
  .menuOptions > div a.active svg,
  .menuOptions > div a.active:hover svg {
    font-size: 40px;
    padding: 7px;
  }

  .serviceIcons {
    max-width: 60px;
  }

  /* container alignments */
  .filteredItemCollection {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }

  .projectSelector {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
  }

  .projectCard {
    max-height: unset;
  }

  .projectCard .backgroundPhoto,
  .photoGalleryHighlight {
    height: 100%;
  }

  .projectCard .backgroundPhoto,
  .photoGalleryHighlight {
    height: 200px;
  }

  .projectCard .backgroundPhoto,
  .photoGalleryHighlight {
    height: 300px;
  }

  .backgroundPhoto img,
  .projectCard:hover .backgroundPhoto img {
    width: auto;
    height: 100%;
  }

  .MuiAccordion-root {
    margin-top: 0;
  }

  .projectTypeSelector {
    padding: 12px;
  }

  .filterContainer {
    margin-bottom: 1rem;
  }

  .filterContainer div {
    width: fit-content;
    display: flex;
    flex-direction: row;
    gap: 5px;
    justify-content: flex-start;
  }

  .allProjectselector div,
  .filterContainer div .customFormControl,
  .filterContainer div .customFormControl div {
    width: 100%;
  }

  .form-check .form-check-input {
    margin-right: 10px;
  }

  .projectCard:hover {
    top: 0;
  }

  .form-check-input {
    width: 18px;
    height: 18px;
  }

  .simpleButton,
  .contactButton {
    padding: 5px 10px;
    font-size: 13px;
    margin: 5px 5px 5px 0;
  }

  .buttonContainer {
    margin-top: 10px;
    padding-top: 0;
  }

  .buttonContainer p {
    margin: 0;
    font-size: 13px;
  }

  .firstInformation .introText:first-of-type {
    font-size: 2.5rem;
    margin-bottom: 0;
    padding-top: 5px;
  }

  .introText:last-of-type {
    font-size: 17px;
    line-height: 1.4;
  }

  /* projects */
  .designContainer {
    min-height: 200px;
    margin-bottom: 1rem;
  }

  .designContainer h3 {
    margin-bottom: 1rem;
  }

  .designContainer:hover {
    top: -2px !important;
  }

  .keyInfoContainer {
    gap: 5px;
  }

  .keyInfoContainer p,
  .filterContainer p {
    width: 100%;
  }

  .filterContainer .textButton {
    width: fit-content;
  }

  .keyInfoContainer .highlightInformation {
    padding-left: 0;
    margin-bottom: 0;
  }

  .experienceChart ul.rb {
    margin-top: 0;
  }

  /* education */
  .rb-item {
    flex-direction: column;
  }

  .introText {
    font-size: 1.5rem;
  }

  .responsiveIcon {
    height: 30px;
    width: 30px;
    background-color: red;
  }

  /* scrollbar */
  /* width */
  ::-webkit-scrollbar {
    width: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 9999;
  }

  .vertical-timeline-element-title,
  h3 {
    font-size: 18px;
  }

  .modalContainer {
    padding: 15px;
  }

  .projectDetail p:nth-of-type(2) {
    font-size: 14px;
  }

  .recruiterInformation {
    margin-bottom: 1.5rem;
    padding: 20px;
  }

  .projectTechnologies img {
    max-height: 50px;
    padding: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .interactiveBlock {
    padding: 20px 0;
  }
  .instyleMockup {
    height: 300px;
  }
  .projectDetail {
    padding: 20px;
  }
  .projectCard .backgroundPhoto,
  .photoGalleryHighlight {
    height: 200px !important;
  }
  .backgroundPhoto img,
  .projectCard:hover .backgroundPhoto img {
    width: 100%;
    height: auto;
  }
}
